import React, { useContext } from "react";
import appContext from "../context/appContext";
import {
  Box,
  Heading,
  Text,
  Distribution,
  Stack,
  Meter,
  Anchor,
} from "grommet";

import { useViewport } from "@headwaters-economics/web-shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import EditBenchmarkButton from "./EditBenchmarkButton";

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 0,
});
const populationFormatterCompact = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 0,
  notation: "compact",
});
const populationFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 0,
  notation: "standard",
});
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

const dataColor = {
  default: [
    "#031E33",
    "#0D4D80",
    "#2984CD",
    "#8D1E07",
    "#331800",
    "#B35A48",
    "#B39448",
  ],
  cool: [
    "#0D4D80",
    "#157BCD",
    "#2A2B8D",
    "#293BCD",
    "#2A8D6F",
    "#29CDB9",
    "#072740",
  ],
  grayscale: ["#222", "#555", "#777", "#aaa", "#b9b9b9", "#ddd", "#fff"],
  warm: [
    "#B31F00",
    "#340900",
    "#C0513A",
    "#801600",
    "#E1A599",
    "#994533",
    "#D17966",
  ],
};

const QuickFactSingleBar = ({ data }) => {
  function maxValue() {
    return data.map((d) => d.value);
  }
  const maxBar = Math.max(...maxValue()) * 1.3;
  const barData = data.map((value, i) => {
    return (
      <Stack key={i}>
        <Box>
          <Meter
            type="bar"
            thickness="medium"
            background="light-1"
            color="heBlue-3"
            max={maxBar}
            values={[
              {
                value: value.value,
                label: value.name,
                color: value.benchmark === true ? "#222" : "#555",
              },
            ]}
          />
        </Box>
        <Box
          fill
          direction="row"
          justify="between"
          align="center"
          pad={{ horizontal: "xxsmall" }}
        >
          <Box direction="row" height="18px" align="center">
            <Text size="8pt" color="#fff" weight="normal" truncate="tip">
              {value.name}
            </Text>
            {value.benchmark === true && <EditBenchmarkButton />}
          </Box>

          <Text size="8pt" weight="normal">
            {currencyFormatter.format(value.value)}
          </Text>
        </Box>
      </Stack>
    );
  });
  return (
    <Box justify="end" gap="xxsmall">
      {barData}
    </Box>
  );
};

const QuickFactBarChart = ({ data, colors = dataColor.grayscale }) => {
  const barData = data.map((value, i) => {
    return (
      <Stack key={value.group}>
        <Meter
          type="bar"
          thickness="medium"
          background="light-2"
          color="heBlue-3"
          width="100%"
          values={[
            {
              value: value.propOfPop * 100,
              label: value.group,
              color: colors[i],
            },
          ]}
        />
        <Box
          fill="vertical"
          direction="row"
          justify="between"
          align="center"
          pad={{ horizontal: "xxsmall", bottom: "4px" }}
        >
          <Text size="8pt" color="#fff">
            {percentFormatter.format(value.propOfPop)}
          </Text>
          <Text size="11pt">{value.group}</Text>
        </Box>
      </Stack>
    );
  });
  return <Box>{barData}</Box>;
};

const QuickFactDistribution = ({ data, colors = dataColor.grayscale }) => {
  const DistData = data.map((value, i) => {
    return {
      value: value.propOfPop,
      label: value.group,
      background: colors[i],
    };
  });
  return (
    <Distribution
      values={DistData.sort(function (a, b) {
        return b.value - a.value;
      })}
      fill
      gap="3px"
    >
      {(value) => (
        <Box
          fill
          background={value.background}
          pad="xxsmall"
          align="center"
          justify="center"
        >
          <Text size="10pt" textAlign="center">
            {value.label}
          </Text>
          <Text size="8pt" textAlign="center">
            ({percentFormatter.format(value.value)})
          </Text>
        </Box>
      )}
    </Distribution>
  );
};

const QuickFacts = ({ primaryGeo, secondaryGeo, suppressHeader }) => {
  const { quickFactsData, benchmarkGeo } = useContext(appContext);
  const { screenSize } = useViewport();

  if (!primaryGeo || !quickFactsData) return null;
  if (!quickFactsData[primaryGeo.id]) return null;
  if (
    primaryGeo.geo_level === "place" &&
    (!secondaryGeo || !quickFactsData[secondaryGeo.id])
  )
    return null;

  const primaryGeoQuickFacts = quickFactsData[primaryGeo.id];
  const topSectorsQuickFacts =
    quickFactsData[
      (primaryGeo.geo_level === "place" ||
      (primaryGeo.geo_level === "tribal" && secondaryGeo)
        ? secondaryGeo
        : primaryGeo
      ).id
    ];

  const spacing = screenSize === "mobile" ? "medium" : "small";

  const QuickFactSingle = ({
    content,
    title,
    year,
    icon,
    gap = "xsmall",
    flex = true,
  }) => {
    return (
      <Box height="100px" justify="between" flex={flex}>
        <Heading
          alignSelf="center"
          level="4"
          size="medium"
          margin="none"
          textAlign="center"
          truncate="tip"
        >
          {title}
        </Heading>
        {/* <Box align="center">{icon}</Box> */}
        <Box justify="center" gap={gap} direction="row" align="center">
          <Text
            weight={900}
            size={screenSize === "desktop" ? "xxlarge" : "xlarge"}
            textAlign="center"
          >
            {content}
          </Text>
        </Box>
        <Text size="9pt" textAlign="center">
          {year}
        </Text>
      </Box>
    );
  };

  const QuickFactHeader = ({ primary, secondary }) => {
    return (
      <Box direction="row" gap="xsmall" align="baseline">
        <Heading level="4" size="medium" margin="0 0 8px">
          {primary}
        </Heading>
        <Text weight={300} size="9pt">
          {secondary}
        </Text>
      </Box>
    );
  };

  const QuickFactContainer = ({
    children,
    background = "#fff",
    pad = spacing,
    round = "2px",
    elevation = "xsmall",
    width = "auto",
    flex = true,
  }) => {
    return (
      <Box
        width={width}
        background={background}
        pad={pad}
        justify="between"
        round={round}
        elevation={elevation}
        flex={flex}
      >
        {children}
      </Box>
    );
  };

  return (
    <Box fill justify="between" elevation="small" background="#c9c9c9">
      <Box>
        {suppressHeader ? null : (
          <Box
            background="brand"
            pad={{ vertical: "xxsmall", horizontal: "xsmall" }}
            height={{ min: "fit-content" }}
          >
            <Heading level="3" size="xlarge" margin="none" truncate="tip">
              <Text size="large" weight="bold">
                Q
              </Text>
              <Text size="small" weight="normal">
                UICK
              </Text>
              <Text size="large" weight="bold">
                F
              </Text>
              <Text size="small" weight="normal">
                ACTS
              </Text>
              &nbsp;&nbsp;
              <Text>
                {primaryGeo
                  ? ["county", "place"].includes(primaryGeo.geo_level)
                    ? primaryGeo.label.slice(0, -4)
                    : primaryGeo.label
                  : ""}
              </Text>
            </Heading>
          </Box>
        )}
        <Box overflow={{ vertical: "auto" }}>
          <Box height={{ min: "fit-content" }} pad={spacing} gap={spacing}>
            <Box direction="row" gap={spacing} justify="between">
              <QuickFactContainer>
                <QuickFactSingle
                  content={
                    primaryGeoQuickFacts.population <= 5000
                      ? populationFormatter.format(
                          primaryGeoQuickFacts.population
                        )
                      : populationFormatterCompact.format(
                          primaryGeoQuickFacts.population
                        )
                  }
                  title="Population"
                  year="2020"
                  icon={
                    <FontAwesomeIcon icon={faUsers} size="lg" color="orange" />
                  }
                />
              </QuickFactContainer>
              <QuickFactContainer width={{ max: "fit-content" }} flex="grow">
                <Heading level="4" size="medium" margin="none">
                  Top 3 Sectors
                </Heading>
                <Box margin={{ right: "medium", bottom: "xsmall" }}>
                  <Text weight={300} size="xsmall">
                    Pct. jobs{" "}
                    {primaryGeo.geo_level === "place" ||
                    (primaryGeo.geo_level === "tribal" && secondaryGeo)
                      ? "in " + secondaryGeo.name.slice(0, -4)
                      : ""}{" "}
                    2020{" "}
                  </Text>
                </Box>
                {topSectorsQuickFacts && topSectorsQuickFacts.topSectors ? (
                  <Box fill gap="xxxsmall">
                    {topSectorsQuickFacts.topSectors.map((data, i) => {
                      return (
                        <Text size="12pt" weight="bold" className="condensed">
                          <Text size="12pt" weight="normal">
                            {i + 1}.{" "}
                          </Text>
                          {data.group}{" "}
                          <Text size="9pt" weight="normal">
                            {percentFormatter.format(data.value)}
                          </Text>
                        </Text>
                      );
                    })}
                    {/* </ol> */}
                  </Box>
                ) : (
                  <Box fill justify="start">
                    <Text size="xsmall">No Job Sector Data Available</Text>
                  </Box>
                )}
              </QuickFactContainer>
            </Box>

            <Box direction="row" height={{ min: "175px" }} gap={spacing}>
              <QuickFactContainer>
                <Heading
                  level="4"
                  size="medium"
                  margin="none"
                  weight="900"
                  textAlign="center"
                >
                  Median Household Income
                </Heading>
                <Box
                  direction="row"
                  gap="xsmall"
                  align="center"
                  justify="center"
                >
                  <Box align="center">
                    <Text
                      weight={900}
                      size={screenSize === "desktop" ? "xxlarge" : "xlarge"}
                      textAlign="center"
                    >
                      {currencyFormatter.format(primaryGeoQuickFacts.income)}
                    </Text>
                  </Box>
                  <Box>
                    <Text size="9pt" textAlign="center">
                      (2020)
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <QuickFactSingleBar
                    data={[
                      {
                        name: primaryGeo.label,
                        value: primaryGeoQuickFacts.income,
                      },
                      {
                        name: benchmarkGeo.name,
                        value: quickFactsData[benchmarkGeo.id]
                          ? quickFactsData[benchmarkGeo.id].income
                          : 0,
                        benchmark: true,
                      },
                    ]}
                  />
                </Box>
              </QuickFactContainer>
              <QuickFactContainer>
                <QuickFactHeader primary="Household Income" secondary="2020" />
                {primaryGeoQuickFacts.incomeCategory ? (
                  <QuickFactDistribution
                    data={primaryGeoQuickFacts.incomeCategory}
                  />
                ) : (
                  <Box fill justify="start">
                    <Text size="xsmall">
                      No Household Income Data Available
                    </Text>
                  </Box>
                )}
              </QuickFactContainer>
            </Box>

            <Box direction="row" round="2px" gap={spacing}>
              <QuickFactContainer>
                <QuickFactHeader primary="Employment" secondary="2020" />
                {primaryGeoQuickFacts.workStatus ? (
                  <QuickFactBarChart data={primaryGeoQuickFacts.workStatus} />
                ) : (
                  <Box justify="start">
                    <Text size="xsmall">No Employment Data Available</Text>
                  </Box>
                )}
              </QuickFactContainer>
            </Box>

            <Box direction="row" gap={spacing} justify="between">
              <QuickFactContainer>
                <QuickFactSingle
                  content={
                    primaryGeoQuickFacts.propMinority
                      ? percentFormatter.format(
                          primaryGeoQuickFacts.propMinority
                        )
                      : "--%"
                  }
                  title="People of Color & Hispanics"
                  year="2020"
                />
              </QuickFactContainer>
              <QuickFactContainer>
                <QuickFactSingle
                  content={
                    primaryGeoQuickFacts.propHsGrad
                      ? percentFormatter.format(primaryGeoQuickFacts.propHsGrad)
                      : "--%"
                  }
                  title="High School Graduates"
                  year="2020"
                />
              </QuickFactContainer>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        pad={{ horizontal: "small" }}
        height={{ min: "fit-content" }}
        direction="row"
        gap="xsmall"
      >
        <Text size="xsmall">
          Sources: Census Bureau and Bureau of Economic Analysis
        </Text>
        <Anchor
          size="xsmall"
          label="How to cite these data"
          href="https://headwaterseconomics.org/tools/economic-profile-system/about-eps/#faq"
        />
      </Box>
    </Box>
  );
};

export default QuickFacts;
